body {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.4rem;
}

.section-heading {
    font-size: 3.4rem;
    font-weight: 900;
    letter-spacing: 1px;
    color: $color-text-heading;
    margin-bottom: 4rem;
    text-align: center;
    position: relative;
    display: inline-block;

    &__light {
        color: #fff;
        margin-bottom: 4rem;
        text-align: center;
        position: relative;
        font-size: 3.4rem;
        font-weight: 900;
        letter-spacing: 1px;
        display: flex;
        align-items: center;
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 0.6rem;
        width: 3.6rem;
        background: #828d9d;
    }

    &::before {
        left: 0;
        transform: translateX(-150%);

        @include respond(phone) {
            transform: translateX(-120%);
        }
    }

    &::after {
        right: 0;
        transform: translateX(150%);

        @include respond(phone) {
            transform: translateX(120%);
        }
    }

    &__wrapper {
        display: flex;
        justify-content: center;
    }
}

.heading-primary {
    font-size: 2.4rem;
    font-weight: 900;
    color: $color-grey-text;
}
