.u-flex {
    display: flex;
}

.u-mt-sm {
    margin-top: 1rem !important;
}
.u-mt-md {
    margin-top: 2rem !important;
}
.u-mt-lg {
    margin-top: 4rem !important;
}
.u-mt-xl {
    margin-top: 6rem !important;
}

.u-mb-sm {
    margin-bottom: 1rem !important;
}
.u-mb-md {
    margin-bottom: 2rem !important;
}
.u-mb-lg {
    margin-bottom: 4rem !important;
}
.u-mb-xl {
    margin-bottom: 6rem !important;
}

.hidden {
    display: none;
}

.show-block {
    display: block !important;
}

.show-flex {
    display: flex !important;
}

.show-ib {
    display: inline-block;
}

.gs_reveal {
    opacity: 0;
    visibility: hidden;
    will-change: transform, opacity;
}
