body {
  font-family: Montserrat, sans-serif;
  font-size: 1.4rem;
}

.section-heading {
  letter-spacing: 1px;
  color: #57606c;
  text-align: center;
  margin-bottom: 4rem;
  font-size: 3.4rem;
  font-weight: 900;
  display: inline-block;
  position: relative;
}

.section-heading__light {
  color: #fff;
  text-align: center;
  letter-spacing: 1px;
  align-items: center;
  margin-bottom: 4rem;
  font-size: 3.4rem;
  font-weight: 900;
  display: flex;
  position: relative;
}

.section-heading:before, .section-heading:after {
  content: "";
  background: #828d9d;
  width: 3.6rem;
  height: .6rem;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.section-heading:before {
  left: 0;
  transform: translateX(-150%);
}

@media only screen and (width <= 37.5em) {
  .section-heading:before {
    transform: translateX(-120%);
  }
}

.section-heading:after {
  right: 0;
  transform: translateX(150%);
}

@media only screen and (width <= 37.5em) {
  .section-heading:after {
    transform: translateX(120%);
  }
}

.section-heading__wrapper {
  justify-content: center;
  display: flex;
}

.heading-primary {
  color: #555;
  font-size: 2.4rem;
  font-weight: 900;
}

* {
  margin: 0;
  padding: 0;
}

*, :before, :after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

@media only screen and (width <= 75em) {
  html {
    font-size: 56.25%;
  }
}

@media only screen and (width <= 56.25em) {
  html {
    font-size: 50%;
  }
}

@media only screen and (width >= 112.5em) {
  html {
    font-size: 68.75%;
  }
}

body {
  color: #555;
  font-family: Montserrat, sans-serif;
  font-size: 1.4rem;
  position: relative;
}

a:link, a:visited {
  color: #3c5375;
}

a:focus {
  outline: none;
}

a:hover, a:active {
  text-decoration: underline;
}

.btn {
  letter-spacing: 1px;
  color: #fff;
  background: #2dad7f;
  border-radius: 10rem;
  outline: none;
  padding: 1.7rem 4.6rem;
  font-size: 2rem;
  font-weight: 600;
  text-decoration: none;
  transition: all .25s;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.btn-cta span {
  z-index: 10;
  color: #fff;
  align-items: center;
  display: flex;
  position: relative;
}

.btn:hover {
  text-decoration: none;
  transform: translateY(-2px)scale(1.02);
  box-shadow: .5rem .5rem 2rem #0003;
}

.btn:focus {
  outline: none;
}

.btn:active {
  box-shadow: none;
  transform: translateY(0)scale(1);
}

.btn:before {
  content: "";
  opacity: 0;
  background: linear-gradient(200.67deg, #2cdb7c 15.93%, #23b76700 61.14%), #2dad7f;
  width: 100%;
  height: 100%;
  transition: all .25s;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.btn:hover:before {
  opacity: 1;
}

.overlay {
  opacity: 0;
  backdrop-filter: blur(3px);
  visibility: hidden;
  background: #00000080;
  width: 100%;
  height: 100%;
  transition: all .4s;
  position: absolute;
  top: 0;
  left: 0;
}

.overlay--active {
  visibility: visible;
  opacity: 1;
  z-index: 6;
}

.logo-sai {
  width: 31rem;
  height: 7.99rem;
}

.logo-sai--footer {
  fill: #fff;
  width: 14rem;
}

.icon {
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  display: inline-block;
}

.icon-phone {
  width: 2rem;
  height: 2rem;
}

.icon-services {
  fill: currentColor;
  width: 3.2rem;
  height: 3.1rem;
}

.icon-pin {
  width: 1.4rem;
  height: 1.4rem;
}

.icon-mail {
  fill: currentColor;
  margin-right: .4rem;
}

.icon-mail--navbar {
  visibility: hidden;
  width: 3rem;
  height: 2.5rem;
}

@media only screen and (width <= 37.5em) {
  .icon-mail--navbar {
    visibility: visible;
  }
}

.icon-socials {
  fill: currentColor;
  stroke: currentColor;
  width: 3.8rem;
  height: 3.8rem;
}

.icon-socials a:link, .icon-socials a:visited {
  fill: #fff;
}

.services-card {
  background: #fff;
  border-radius: 6px;
  flex-direction: column;
  flex: 0 36rem;
  margin: 2.4rem;
  transition: all .25s;
  display: flex;
  overflow: hidden;
  box-shadow: 5px 5px 20px #415a804a;
}

@media only screen and (width <= 37.5em) {
  .services-card {
    flex: 0 44rem;
  }
}

.services-card__heading {
  color: #fff;
  z-index: 10;
  background: #336095;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  justify-content: center;
  align-items: center;
  padding: 2.7rem 4rem 2.7rem 1rem;
  font-size: 2.5rem;
  display: flex;
  position: relative;
}

.services-card__list-box {
  flex-direction: column;
  height: 100%;
  padding: 4rem;
  display: flex;
}

.services-card__link {
  align-items: center;
  margin-top: auto;
  font-size: 1.5rem;
  display: flex;
}

.services-card:hover {
  transform: scale(1.04);
  box-shadow: 10px 10px 35px #415a8073;
}

.services-card__heading:after {
  content: "";
  opacity: 0;
  z-index: -1;
  background: linear-gradient(193.45deg, #599dd2 -20.8%, #36709d00 63.1%), linear-gradient(20.47deg, #23b767 -11.01%, #23b76700 76.47%);
  width: 100%;
  height: 100%;
  transition: all .25s;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.services-card:hover .services-card__heading:after {
  opacity: 1;
}

.list {
  list-style: none;
  display: inline-block;
}

.list__item {
  color: #555;
  align-items: center;
  padding: 1.6rem 0;
  font-size: 1.7rem;
  font-weight: 600;
  display: flex;
}

.list__item:before {
  content: "";
  background-image: url("arrow.16f1c976.svg");
  background-size: cover;
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  display: inline-block;
}

@supports (mask-image: url()) {
  .list__item:before {
    background-color: #2dad7f;
    background-image: none;
    mask-image: url("arrow.16f1c976.svg");
    mask-size: cover;
  }
}

.menu-ham {
  z-index: 10;
  outline: none;
  width: 5rem;
  height: 24px;
  margin-left: 1.6rem;
  display: none;
  position: relative;
}

@media only screen and (width <= 37.5em) {
  .menu-ham {
    display: block;
  }
}

.menu-ham .line {
  background: #3c5375;
  border-radius: 2px;
  width: 5rem;
  height: 4px;
  transition: all .3s;
  display: block;
  position: absolute;
  left: 0;
}

.menu-ham .line.line-1 {
  top: 0;
}

.menu-ham .line.line-2 {
  top: 50%;
}

.menu-ham .line.line-3 {
  top: 100%;
}

.menu-ham:hover .line-1 {
  transform: translateY(-2px);
}

.menu-ham:hover .line-3 {
  transform: translateY(2px);
}

.menu-ham.active .line-1 {
  transform: translateY(12px)translateX(0)rotate(45deg);
}

.menu-ham.active .line-2 {
  opacity: 0;
}

.menu-ham.active .line-3 {
  transform: translateY(-12px)translateX(0)rotate(-45deg);
}

.container {
  max-width: 144rem;
  margin: 0 auto;
  padding: 0 6rem;
}

@media only screen and (width <= 37.5em) {
  .container {
    padding: 0 4rem;
  }
}

.navbar {
  z-index: 1000;
  position: relative;
}

.navbar__nav {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navbar .container {
  z-index: 1000;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.navbar__bg {
  z-index: 3;
  background: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (width <= 37.5em) {
  .navbar__bg {
    box-shadow: 0 1rem 1rem #1214361a;
  }
}

.navbar__logo-box {
  z-index: 1000;
  align-items: center;
  display: flex;
  position: relative;
}

@media only screen and (width <= 37.5em) {
  .navbar__logo-box {
    padding: 1rem 0;
  }
}

.navbar__logo-box .logo-sai {
  width: 24rem;
}

.navbar__nav-sub-heading {
  color: #b7b7b7;
  font-size: 1.4rem;
  font-weight: 600;
  position: absolute;
  bottom: 2.3rem;
  left: 5.4rem;
  transform: translateY(50%);
}

.navbar__nav-list {
  letter-spacing: 1px;
  z-index: 10;
  font-size: 1.6rem;
  list-style-type: none;
  transition: all .4s cubic-bezier(.78, 0, .23, 1);
  display: flex;
}

@media only screen and (width <= 37.5em) {
  .navbar__nav-list {
    opacity: 1;
    z-index: 2;
    background: #fff;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: absolute;
    top: -90%;
    right: 0;
  }
}

.navbar__nav-list--active {
  top: 100%;
  right: 0;
  opacity: 1 !important;
}

@media only screen and (width <= 37.5em) {
  .navbar__nav-list--active {
    box-shadow: 1rem 2rem 3rem #0000004d;
  }
}

.navbar__nav-list a:not(.menu-ham) {
  color: #555;
  outline: none;
  align-items: center;
  padding: 4rem 3.8rem;
  text-decoration: none;
  display: flex;
}

@media only screen and (width <= 37.5em) {
  .navbar__nav-list a:not(.menu-ham) {
    padding: 3.6rem 3rem;
  }
}

.navbar__nav-list li {
  align-items: center;
  display: flex;
}

@media only screen and (width <= 37.5em) {
  .navbar__nav-list li:not(:last-child) {
    border-bottom: 1px solid #ededed;
  }
}

.navbar__nav-list--contact svg {
  display: none;
}

@media only screen and (width <= 37.5em) {
  .navbar__nav-list--contact svg {
    display: block;
  }
}

.navbar__nav-list--lang {
  padding: 0;
}

.navbar__nav-list--lang a:link, .navbar__nav-list--lang a:visited {
  padding-left: 1rem;
}

.navbar__nav-list--lang a:link svg, .navbar__nav-list--lang a:visited svg {
  width: 1.8rem;
  height: 1.8rem;
}

@media only screen and (width <= 37.5em) {
  .navbar__nav-list--lang a:link, .navbar__nav-list--lang a:visited {
    padding-left: 1.6rem;
    font-size: 1.6rem;
  }

  .navbar__nav-list--lang a:link svg, .navbar__nav-list--lang a:visited svg {
    width: 2.2rem;
    height: 2.2rem;
  }
}

.navbar__nav-list--lang-link:link, .navbar__nav-list--lang-link:visited {
  color: #777;
}

.navbar__nav-list--lang-link:link:hover, .navbar__nav-list--lang-link:visited:hover {
  text-decoration: underline;
}

.header {
  background: #1d3c6b;
  position: relative;
}

.header .container {
  z-index: 5;
  clip-path: polygon(0% 0%, 63rem 0, 52rem 100%, 0% 100%);
  background: radial-gradient(50% 90.81% at 51.25% 54.06%, #37729e 0%, #428bc100 100%), #1d3c6b;
  align-items: center;
  height: 100%;
  display: flex;
  position: relative;
  box-shadow: 2rem 0 2rem #0003;
}

@media only screen and (width <= 56.25em) {
  .header .container {
    clip-path: polygon(0% 0%, 100% 0, 100% 100%, 0% 100%);
    background: radial-gradient(50% 90.81% at 51.25% 54.06%, #1d3c6b4d 0%, #428bc100 100%), #1d3c6bb3;
  }
}

.header .container__img-box {
  z-index: 1;
  width: 85%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

@media only screen and (width <= 56.25em) {
  .header .container__img-box {
    width: 100%;
  }
}

.header .container__img-box .slideshow li {
  z-index: 0;
  opacity: 0;
  filter: saturate(0%);
  width: 80%;
  height: 100%;
  list-style: none;
  animation: 30s linear infinite headerAnimation;
  position: absolute;
  top: 0;
  right: 0;
}

@media only screen and (width <= 56.25em) {
  .header .container__img-box .slideshow li {
    width: 100%;
  }
}

.header .container__img-box .slideshow li:first-child {
  background: url("mechanic1.695e2adc.jpg") 60% / cover no-repeat;
}

.header .container__img-box .slideshow li:nth-child(2) {
  background: url("mechanic2.dc7da8c2.jpg") 60% / cover no-repeat;
  animation-delay: 10s;
}

.header .container__img-box .slideshow li:nth-child(3) {
  background: url("mechanic3.e7e19265.jpg") 60% / cover no-repeat;
  animation-delay: 20s;
}

.header .container__img-box .slideshow:after {
  content: "";
  background: linear-gradient(0deg, #1d3c6b66, #1d3c6b40);
  width: 100%;
  height: 100%;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.header__heading-box {
  z-index: 10;
  flex: 0 50rem;
  padding: 6rem 0;
  position: relative;
}

@media only screen and (width <= 37.5em) {
  .header__heading-box {
    padding: 7rem 0;
  }
}

.header__heading-primary {
  color: #fff;
  text-shadow: .5rem .5rem 1rem #0000003d;
  font-size: 3.5rem;
  font-weight: 900;
}

.header__heading-primary--sub {
  color: #53b7ff;
  text-shadow: .5rem .5rem 1rem #0000003d;
  margin-top: 4.8rem;
  font-size: 2.2rem;
  font-weight: 500;
}

.header__vector-line {
  position: relative;
}

.header__vector-line svg {
  filter: drop-shadow(.5rem .5rem 1rem #0000003d);
  width: 20.2rem;
  height: 2.9rem;
  position: absolute;
  bottom: -2.5rem;
  left: 0;
}

.header__vector-line svg.fr {
  width: 22.2rem;
  height: 4.9rem;
  margin-top: 0;
  bottom: -3.5rem;
}

.section {
  padding: 10rem;
}

@media only screen and (width <= 56.25em) {
  .section {
    padding: 8rem 2rem;
  }
}

.section-gallery {
  background: #333;
}

.section-gallery .img-box {
  height: 30rem;
}

.section-gallery .img-box img {
  height: 100%;
}

.section-services {
  background: url("pattern.e5a80d03.png");
  padding: 8rem;
}

@media only screen and (width <= 37.5em) {
  .section-services {
    padding: 6rem;
  }
}

.section-services div:first-child {
  text-align: center;
}

.section-services div:first-child span {
  margin-bottom: 4rem;
  font-size: 1.8rem;
  display: inline-block;
}

@media only screen and (width <= 37.5em) {
  .section-services div:first-child span {
    margin-bottom: 1.6rem;
  }
}

.section-services .container {
  justify-content: center;
  height: 100%;
  padding: 0;
  display: flex;
}

@media only screen and (width <= 75em) {
  .section-services .container {
    flex-wrap: wrap;
  }
}

.section-services .section-heading {
  margin-bottom: 1rem;
}

.section-map {
  color: #fff;
  background: url("section_bg.3ef32b6a.jpg") 50% / cover no-repeat;
  padding: 8rem 4rem;
  position: relative;
}

@media only screen and (width <= 56.25em) {
  .section-map {
    padding: 6rem 0;
  }
}

.section-map .container {
  padding: 0 2rem;
  display: flex;
}

.section-map__left-bg {
  background: #2e4568f0;
  width: 60%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (width <= 56.25em) {
  .section-map__left-bg {
    width: 100%;
    height: 60%;
  }
}

.section-map__table {
  margin-top: 1.6rem;
  font-size: 1.5rem;
}

.section-map__table td {
  padding: .5rem 0;
}

.section-map__table tbody > tr > td:first-child {
  padding-right: 5rem;
}

.section-map__right-bg {
  background: #eceef6f0;
  width: 40%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

@media only screen and (width <= 56.25em) {
  .section-map__right-bg {
    width: 100%;
    height: 40%;
    top: 60%;
  }
}

.section-map__hours {
  z-index: 100;
  margin-bottom: 3rem;
  display: flex;
  position: relative;
}

.section-map__hours ul {
  list-style: none;
}

.section-map__hours ul li {
  padding: .4rem;
}

.section-map__weekdays {
  border-top: 1px solid #fff;
}

@media only screen and (width <= 90.625em) {
  .section-map__weekdays {
    margin-left: 0;
  }
}

.section-map .section-heading {
  text-align: left;
  align-items: center;
  display: flex;
  position: relative;
}

@media only screen and (width <= 37.5em) {
  .section-map .section-heading {
    margin-bottom: 1.6rem;
    transform: scale(.8);
  }
}

.section-map .container {
  justify-content: space-around;
  display: flex;
}

@media only screen and (width <= 56.25em) {
  .section-map .container {
    flex-direction: column;
    align-items: center;
  }
}

.section-map__left-box {
  flex-direction: column;
  align-items: center;
  margin-right: 2.6rem;
  display: flex;
}

@media only screen and (width <= 56.25em) {
  .section-map__left-box {
    margin-right: 0;
  }
}

.section-map__google-map {
  background: #f2f3f4;
  width: 40%;
  padding-bottom: 20%;
  transition: all .25s;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 35px #19202b5c;
}

@media only screen and (width <= 90.625em) {
  .section-map__google-map {
    width: 60%;
    padding-bottom: 50%;
  }
}

@media only screen and (width <= 56.25em) {
  .section-map__google-map {
    width: 90%;
    margin: 3rem 0 2rem;
    padding-bottom: 90%;
  }
}

.section-map__google-map:hover, .section-map__google-map:active {
  transform: scale(1.04);
  box-shadow: 5px 10px 50px #19202b80;
}

.section-map__google-map iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.section-map__icon {
  fill: currentColor;
  width: 5.2rem;
  height: 6.1rem;
  margin-right: 2.4rem;
}

@media only screen and (width <= 37.5em) {
  .section-map__icon {
    width: 7.5rem;
  }
}

@media only screen and (width <= 56.25em) {
  .section-map__weekdays, .section-map .btn {
    margin-left: 0;
  }
}

.section-footer {
  background: #1c1d1f;
  min-height: 10rem;
}

.section-footer__logo {
  width: 16rem;
}

.section-footer .container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.footer__outer-box {
  text-align: center;
}

.footer__outer-box h2 {
  color: #b0b0b0;
  font-size: 1.5rem;
  font-weight: 600;
}

.footer__left-list {
  color: #fff;
  list-style: none;
}

.footer__left-list li {
  align-items: center;
  padding: .4rem 0;
  font-size: 1.4rem;
  display: flex;
}

@media only screen and (width <= 28.7em) {
  .footer__left-list > li:nth-child(2) > span {
    display: none;
  }
}

.footer__left-list a:link, .footer__left-list a:visited {
  color: #fff;
  text-decoration: none;
}

.footer__left-list a:hover, .footer__left-list a:active {
  text-decoration: underline;
}

.footer__copyright {
  color: #888;
  text-align: center;
  margin-top: 1.8rem;
  font-size: 1.2rem;
}

.footer__socials {
  margin-top: 1.4rem;
}

.footer__socials-link:link, .footer__socials-link:visited {
  color: #0000;
}

.u-flex {
  display: flex;
}

.u-mt-sm {
  margin-top: 1rem !important;
}

.u-mt-md {
  margin-top: 2rem !important;
}

.u-mt-lg {
  margin-top: 4rem !important;
}

.u-mt-xl {
  margin-top: 6rem !important;
}

.u-mb-sm {
  margin-bottom: 1rem !important;
}

.u-mb-md {
  margin-bottom: 2rem !important;
}

.u-mb-lg {
  margin-bottom: 4rem !important;
}

.u-mb-xl {
  margin-bottom: 6rem !important;
}

.hidden {
  display: none;
}

.show-block {
  display: block !important;
}

.show-flex {
  display: flex !important;
}

.show-ib {
  display: inline-block;
}

.gs_reveal {
  opacity: 0;
  visibility: hidden;
  will-change: transform, opacity;
}

@keyframes headerAnimation {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
  }

  20% {
    opacity: 1;
    animation-timing-function: ease-out;
  }

  40% {
    opacity: 1;
  }

  60% {
    opacity: 0;
  }
}

.lds-ring {
  width: 80px;
  height: 80px;
  display: inline-block;
  position: relative;
}

.lds-ring div {
  box-sizing: border-box;
  border: 8px solid #0000;
  border-top-color: #fff;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  margin: 8px;
  animation: 1.2s cubic-bezier(.5, 0, .5, 1) infinite lds-ring;
  display: block;
  position: absolute;
}

.lds-ring div:first-child {
  animation-delay: -.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.section__tires .box {
  text-align: center;
  padding: 14rem 0;
}
/*# sourceMappingURL=index.207846ff.css.map */
