* {
    margin: 0;
    padding: 0;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}
html {
    font-size: 62.5%;
    box-sizing: border-box;

    @include respond(tab-land) {
        font-size: 56.25%;
    }

    @include respond(tab-port) {
        font-size: 50%;
    }
    
    @include respond(desktop) {
        font-size: 68.75%;
    }    
}

body {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.4rem;
    color: $color-grey-text;
    position: relative;
}

a:link,
a:visited {
    color: $color-main-2;
}

a:focus {
    outline: none;
}

a:hover,
a:active {
    text-decoration: underline;
}
