@use "sass:math";

.btn {
  font-size: 2rem;
  letter-spacing: 1px;
  font-weight: 600;
  color: #fff;
  text-decoration: none;
  background: $color-sec-1;
  display: inline-block;
  padding: 1.7rem 4.6rem;
  border-radius: 10rem;
  outline: none;
  transition: all 0.25s;
  position: relative;
  overflow: hidden;

  &-cta span {
    display: flex;
    position: relative;
    z-index: 10;
    align-items: center;
    color: #fff;
  }

  &:hover {
    transform: translateY(-2px) scale(1.02);
    box-shadow: 0.5rem 0.5rem 2rem rgba($color-black, 0.2);
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }

  &:active {
    transform: translateY(0) scale(1);
    box-shadow: none;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: all 0.25s;
    display: inline-block;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        200.67deg,
        #2cdb7c 15.93%,
        rgba(35, 183, 103, 0) 61.14%
      ),
      #2dad7f;
  }
}

.btn:hover::before {
  opacity: 1;
}

.overlay {
  background: rgba($color-black, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: (3px);
  backdrop-filter: blur(3px);
  transition: all 0.4s;
  visibility: hidden;

  &--active {
    visibility: visible;
    opacity: 1;
    z-index: 6;
  }
}

.logo-sai {
  width: 31rem;
  height: 7.99rem;

  &--footer {
    width: 14rem;
    fill: #fff;
  }
}

.icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  margin-right: 1rem;

  &-phone {
    width: 2rem;
    height: 2rem;
  }

  &-services {
    width: 3.2rem;
    height: 3.1rem;
    fill: currentColor;
  }

  &-pin {
    width: 1.4rem;
    height: 1.4rem;
  }

  &-mail {
    margin-right: 0.4rem;
    fill: currentColor;

    &--navbar {
      width: 3rem;
      height: 2.5rem;
      visibility: hidden;

      @include respond(phone) {
        visibility: visible;
      }
    }
  }

  &-socials {
    height: 3.8rem;
    width: 3.8rem;
    fill: currentColor;
    stroke: currentColor;

    a:link,
    a:visited {
      fill: #fff;
    }
  }
}

.services-card {
  background: #fff;
  flex: 0 1 36rem;
  box-shadow: 5px 5px 20px rgba(65, 90, 128, 0.29);
  border-radius: 6px;
  margin: 2.4rem;
  transition: ease all 0.25s;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  @include respond(phone) {
    flex: 0 1 44rem;
  }

  &__heading {
    font-size: 2.5rem;
    background: #336095;
    color: #fff;
    padding: 2.7rem 1rem;
    padding-right: 4rem;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    position: relative;
    display: inline-block;
    z-index: 10;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__list-box {
    padding: 4rem;
    height: 100%;

    display: flex;
    flex-direction: column;
  }

  &__link {
    margin-top: auto;
    font-size: 1.5rem;

    display: flex;
    align-items: center;
  }

  &:hover {
    transform: scale(1.04);
    box-shadow: 10px 10px 35px rgba(65, 90, 128, 0.45);
  }

  &__heading::after {
    content: '';
    display: inline-block;
    transition: ease all 0.25s;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: linear-gradient(
        193.45deg,
        #599dd2 -20.8%,
        rgba(54, 112, 157, 0) 63.1%
      ),
      linear-gradient(20.47deg, #23b767 -11.01%, rgba(35, 183, 103, 0) 76.47%);
  }

  &:hover &__heading::after {
    opacity: 1;
  }
}

.list {
  list-style: none;
  display: inline-block;

  &__item {
    font-size: 1.7rem;
    font-weight: 600;
    color: $color-grey-text;
    padding: 1.6rem 0;
    display: flex;
    align-items: center;

    &::before {
      content: '';
      display: inline-block;
      width: 2rem;
      height: 2rem;
      margin-right: 1rem;

      //for older browsers
      background-image: url('../img/svg/arrow.svg');
      background-size: cover;

      @supports (mask-image: url()) or (-webkit-mask-image: url()) {
        background-image: none;
        background-color: $color-sec-1;
        -webkit-mask-image: url('../img/svg/arrow.svg');
        mask-image: url('../img/svg/arrow.svg');
        -webkit-mask-size: cover;
        mask-size: cover;
      }
    }
  }
}

.menu-ham {
  $height-icon: 24px;
  $width-line: 5rem;
  $height-line: 4px;
  $rotation: 45deg;
  $translateY: math.div($height-icon, 2);
  $translateX: 0;
  // position: absolute;
  // top: 50%;
  // right: 2.6rem;
  // transform: translateY(-50%);
  height: $height-icon;
  width: $width-line;
  display: none;
  margin-left: 1.6rem;
  z-index: 10;
  outline: none;
  position: relative;

  @include respond(phone) {
    display: block;
  }

  .line {
    display: block;
    background: $color-main-2;
    width: $width-line;
    height: $height-line;
    position: absolute;
    left: 0;
    border-radius: math.div($height-line, 2);
    transition: all 0.3s;

    &.line-1 {
      top: 0;
    }

    &.line-2 {
      top: 50%;
    }

    &.line-3 {
      top: 100%;
    }
  }

  &:hover {
    .line-1 {
      transform: translateY(math.div($height-line, 2) * -1);
    }

    .line-3 {
      transform: translateY(math.div($height-line, 2));
    }
  }

  &.active {
    .line-1 {
      transform: translateY($translateY) translateX($translateX)
        rotate($rotation);
    }

    .line-2 {
      opacity: 0;
    }

    .line-3 {
      transform: translateY($translateY * -1) translateX($translateX * -1)
        rotate($rotation * -1);
    }
  }
}
