@keyframes headerAnimation {

    0% {
        opacity: 0;
        animation-timing-function: ease-in;
    }

    20% {
        opacity: 1;
        animation-timing-function: ease-out;
    }

    40% {
        opacity: 1;
    }

    60% {
        opacity: 0;
    }
}