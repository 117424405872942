.container {
  max-width: 144rem;
  margin: 0 auto;
  padding: 0 6rem;

  @include respond(phone) {
    padding: 0 4rem;
  }
}

.navbar {
  z-index: 1000;
  position: relative;

  &__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1000;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #fff;
    z-index: 3;

    @include respond(phone) {
      box-shadow: 0 1rem 1rem rgba(18, 20, 54, 0.1);
    }
  }

  &__logo-box {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1000;

    @include respond(phone) {
      padding: 1rem 0;
    }

    .logo-sai {
      width: 24rem;
    }
  }

  &__nav-sub-heading {
    color: $color-grey-1;
    font-size: 1.4rem;
    font-weight: 600;
    position: absolute;
    bottom: 2.3rem;
    left: 5.4rem;
    transform: translateY(50%);
  }

  &__nav-list {
    display: flex;
    list-style-type: none;
    font-size: 1.6rem;
    letter-spacing: 1px;
    transition: all cubic-bezier(0.78, 0, 0.23, 1) 0.4s;
    z-index: 10;

    @include respond(phone) {
      flex-direction: column;
      align-items: center;
      opacity: 1;
      position: absolute;
      top: -90%;
      right: 0;
      width: 100%;
      z-index: 2;
      background: #fff;
    }

    &--active {
      opacity: 1 !important;
      top: 100%;
      right: 0;

      @include respond(phone) {
        box-shadow: 1rem 2rem 3rem rgba($color-black, 0.3);
      }
    }

    a:not(.menu-ham) {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: $color-grey-text;
      padding: 4rem 3.8rem;
      outline: none;

      @include respond(phone) {
        padding: 3.6rem 3rem;
      }
    }

    li {
      display: flex;
      align-items: center;
    }

    li:not(:last-child) {
      @include respond(phone) {
        border-bottom: 1px solid #ededed;
      }
    }

    &--contact {
      svg {
        display: none;

        @include respond(phone) {
          display: block;
        }
      }
    }

    &--lang {
      padding: 0;

      a:link,
      a:visited {
        padding-left: 1rem;

        svg {
          width: 1.8rem;
          height: 1.8rem;
        }

        @include respond(phone) {
          font-size: 1.6rem;
          padding-left: 1.6rem;

          svg {
            width: 2.2rem;
            height: 2.2rem;
          }
        }
      }

      &-link:link,
      &-link:visited {
        color: $color-blue-text;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.header {
  position: relative;
  background: $color-bg-header;

  .container {
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    background-size: 80%;
    z-index: 5;
    background: radial-gradient(
        50% 90.81% at 51.25% 54.06%,
        #37729e 0%,
        rgba(66, 139, 193, 0) 100%
      ),
      $color-bg-header;
    clip-path: polygon(0% 0%, 63rem 0, 52rem 100%, 0% 100%);
    box-shadow: 2rem 0 2rem rgba($color-black, 0.2);

    @include respond(tab-port) {
      clip-path: polygon(0% 0%, 100% 0, 100% 100%, 0% 100%);
      background: radial-gradient(
          50% 90.81% at 51.25% 54.06%,
          rgba($color-bg-header, 0.3) 0%,
          rgba(66, 139, 193, 0) 100%
        ),
        rgba($color-bg-header, 0.7);
    }

    &__img-box {
      position: absolute;
      width: 85%;
      height: 100%;
      top: 0;
      z-index: 1;
      right: 0;

      @include respond(tab-port) {
        width: 100%;
      }

      .slideshow li {
        width: 80%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 0;
        opacity: 0;
        list-style: none;
        filter: saturate(0%);
        animation: headerAnimation 30s linear infinite;

        @include respond(tab-port) {
          width: 100%;
        }
      }

      .slideshow li:nth-child(1) {
        background: url('../img/mechanic1.jpg') 60% center / cover no-repeat;
      }

      .slideshow li:nth-child(2) {
        background: url('../img/mechanic2.jpg') 60% center / cover no-repeat;
        animation-delay: 10s;
      }

      .slideshow li:nth-child(3) {
        background: url('../img/mechanic3.jpg') 60% center / cover no-repeat;
        animation-delay: 20s;
      }

      .slideshow::after {
        content: '';
        position: absolute;
        display: inline-block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          0deg,
          rgba($color-bg-header, 0.4),
          rgba($color-bg-header, 0.25)
        );
        width: 100%;
        height: 100%;
      }
    }
  }

  &__heading-box {
    flex: 0 1 50rem;
    position: relative;
    z-index: 10;
    padding: 6rem 0;

    @include respond(phone) {
      padding: 7rem 0;
    }
  }

  &__heading-primary {
    color: #fff;
    text-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.24);
    font-size: 3.5rem;
    font-weight: 900;
  }

  &__heading-primary--sub {
    font-size: 2.2rem;
    font-weight: 500;
    margin-top: 4.8rem;
    color: #53b7ff;
    text-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.24);
  }

  &__vector-line {
    position: relative;

    svg {
      position: absolute;
      bottom: -2.5rem;
      left: 0;
      width: 20.2rem;
      height: 2.9rem;
      filter: drop-shadow(0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.24));

      &.fr {
        margin-top: 0rem;
        width: 22.2rem;
        height: 4.9rem;
        bottom: -3.5rem;
      }
    }
  }
}

.section {
  padding: 10rem;

  @include respond(tab-port) {
    padding: 8rem 2rem;
  }

  &-gallery {
    background: #333;

    .img-box {
      height: 30rem;

      img {
        height: 100%;
      }
    }
  }

  &-services {
    padding: 8rem;
    background: url('../img/pattern.png') repeat;

    @include respond(phone) {
      padding: 6rem;
    }

    div:nth-child(1) {
      text-align: center;

      span {
        margin-bottom: 4rem;
        display: inline-block;
        font-size: 1.8rem;

        @include respond(phone) {
          margin-bottom: 1.6rem;
        }
      }
    }

    .container {
      display: flex;
      justify-content: center;
      height: 100%;
      padding: 0;

      @include respond(tab-land) {
        flex-wrap: wrap;
      }
    }

    .section-heading {
      margin-bottom: 1rem;
    }
  }

  &-map {
    background: url('../img/section_bg.jpg') 50% 50% / cover no-repeat;
    position: relative;
    color: #fff;
    padding: 8rem 4rem;

    @include respond(tab-port) {
      padding: 6rem 0;
    }

    .container {
      display: flex;
      padding: 0 2rem;
    }

    &__left-bg {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 60%;
      background: rgba(46, 69, 104, 0.94);

      @include respond(tab-port) {
        width: 100%;
        height: 60%;
      }
    }

    &__table {
      font-size: 1.5rem;
      margin-top: 1.6rem;
      td {
        padding: 0.5rem 0;
      }

      & tbody > tr > td:first-child {
        padding-right: 5rem;
      }
    }

    &__right-bg {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 40%;
      background: rgba(236, 238, 246, 0.94);

      @include respond(tab-port) {
        width: 100%;
        height: 40%;
        top: 60%;
      }
    }

    &__hours {
      display: flex;
      position: relative;
      z-index: 100;
      margin-bottom: 3rem;

      ul {
        list-style: none;

        li {
          padding: 0.4rem;
        }
      }
    }

    &__weekdays {
      border-top: 1px solid #fff;

      @media only screen and (max-width: 90.625em) {
        margin-left: 0;
      }
    }

    .section-heading {
      display: flex;
      align-items: center;
      position: relative;
      text-align: left;

      @include respond(phone) {
        transform: scale(0.8);
        margin-bottom: 1.6rem;
      }
    }

    .container {
      display: flex;
      justify-content: space-around;

      // @media only screen and (max-width: 90.625em) {
      //     flex-direction: column;
      // }
      @include respond(tab-port) {
        flex-direction: column;
        align-items: center;
      }
    }

    &__left-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 2.6rem;

      @include respond(tab-port) {
        margin-right: 0;
      }
    }

    &__google-map {
      box-shadow: 0px 4px 35px rgba(25, 32, 43, 0.36);
      transition: all 0.25s;
      background: #f2f3f4;
      position: relative;
      // z-index: 20;
      overflow: hidden;
      width: 40%;
      padding-bottom: 20%;

      @media only screen and (max-width: 90.625em) {
        width: 60%;
        padding-bottom: 50%;
        // order: -1;
      }

      @include respond(tab-port) {
        width: 90%;
        padding-bottom: 90%;
        margin: 3rem 0 2rem 0;
      }

      &:hover,
      &:active {
        transform: scale(1.04);
        box-shadow: 5px 10px 50px rgba(25, 32, 43, 0.5);
      }

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;

        @include respond(tab-land) {
        }
      }
    }

    &__icon {
      height: 6.1rem;
      width: 5.2rem;
      margin-right: 2.4rem;
      fill: currentColor;

      @include respond(phone) {
        width: 7.5rem;
      }
    }

    &__weekdays,
    .btn {
      // margin-left: 9rem;

      @include respond(tab-port) {
        margin-left: 0;
      }
    }
  }

  &-footer {
    min-height: 10rem;
    background: #1c1d1f;
    // padding: 4rem 0 2rem 0;

    &__logo {
      width: 16rem;
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

.footer {
  &__outer-box {
    text-align: center;

    h2 {
      font-size: 1.5rem;
      color: #b0b0b0;
      font-weight: 600;
    }
  }

  &__left-list {
    list-style: none;
    color: #fff;

    li {
      padding: 0.4rem 0;
      font-size: 1.4rem;
      display: flex;
      align-items: center;
    }

    & > li:nth-child(2) > span {
      @media only screen and (max-width: 28.7em) {
        display: none;
      }
    }

    a:link,
    a:visited {
      text-decoration: none;
      color: #fff;
    }

    a:hover,
    a:active {
      text-decoration: underline;
    }
  }

  &__copyright {
    color: #888;
    font-size: 1.2rem;
    text-align: center;
    margin-top: 1.8rem;
  }

  &__socials {
    margin-top: 1.4rem;

    &-link:link,
    &-link:visited {
      color: transparent;
    }
  }
}
