//MEDIA QUERIES MANAGER

/*
0 - 600px:              Phone
600px - 900px:          Tablet portrait
900px - 1200px:         Tablet landscape
1200px- 1800px:         Normal desktop
1800px + :              Large desktop

$breakpoint argument choices:
- phone
- tab-port
- tab-land
- big-desktop
*/

@mixin respond($breakpoint) {

    @if $breakpoint == phone {
        @media only screen and (max-width: 37.5em) { @content };  //600px
    }
    
    @if $breakpoint == tab-port {
        @media only screen and (max-width: 56.25em) { @content };  //900px
    }
    
    @if $breakpoint == tab-land {
        @media only screen and (max-width: 75em) { @content };  //1200px
    }
    
    @if $breakpoint == desktop {
        @media only screen and (min-width: 112.5em) { @content };  //1800px
    }

}